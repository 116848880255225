import React from 'react';
import Header from './commom/Header'
import asyncComponent from './commom/AsyncComponent'

import { Route, Switch } from "react-router-dom";

import './App.css';
const Home = asyncComponent(() => import('./pages/home/Home'))
const Article = asyncComponent(() => import('./pages/article/Article'))
const About = asyncComponent(() => import('./pages/about/About'))
const Policy = asyncComponent(() => import('./pages/policy/Policy'))
const Support = asyncComponent(() => import('./pages/support/Support'))


class App extends React.Component {
  render() {
    var isShow = false//['/agreement', '/policy', '/support'].indexOf(document.location.pathname) < 0 
    return (
      <div className="App">
        {isShow ? <Header></Header> : ""}
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/article/:id" component={Article} />
          <Route path="/policy" component={Policy} />
          <Route path="/support" component={Support} />
        </Switch>
      </div>
    );
  }
}

export default App;
