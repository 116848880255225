import React from 'react';
import style from './Header.module.scss';


class Header extends React.Component {
    goto() {
        this.props.history.push('/');
    }

    render() {
        return (
            <div className={style.Header}>
                <a className={style.logo} href='/'>
                    xTerm256
                </a>
                {/* <a className={style.title} href='/'>Home</a>
                <a className={style.title} href='/about'>About</a> */}
            </div>
        );
    }
}

export default Header;
